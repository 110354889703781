import {createSvgIcon} from "@mui/material";
import React from "react";

const SpringIcon = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height="1em"
        width="1em"
    >
        <path d="M21.854 1.416a10.45 10.45 0 01-1.284 2.247A11.967 11.967 0 103.852 20.776l.444.395a11.954 11.954 0 0019.632-8.297c.346-3.013-.568-6.865-2.074-11.458zM5.58 20.875a1.017 1.017 0 11-.149-1.433 1.04 1.04 0 01.149 1.432zm16.199-3.581c-2.939 3.926-9.26 2.593-13.286 2.79 0 0-.716.05-1.432.148 0 0 .272-.123.618-.247 2.84-.987 4.173-1.185 5.901-2.074 3.235-1.654 6.47-5.284 7.112-9.038-1.235 3.606-4.988 6.717-8.396 7.976-2.346.865-6.568 1.704-6.568 1.704l-.173-.099c-2.865-1.407-2.963-7.63 2.272-9.63 2.296-.89 4.47-.395 6.963-.988 2.643-.617 5.705-2.593 6.94-5.186 1.382 4.174 3.061 10.643.049 14.644z" />
    </svg>,
"Spring"
)

export default SpringIcon;


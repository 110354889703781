import {createSvgIcon} from "@mui/material";

const CIcon = createSvgIcon(
    <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="24px" height="24px">

<defs>
</defs>
        <g  transform="translate(1.4065934065934016 1.4065934065934016) scale(0.5 0.5)" >
	<path d="M 82.702 21.231 L 46.734 0.465 c -1.073 -0.619 -2.395 -0.619 -3.468 0 L 7.298 21.231 c -1.073 0.62 -1.734 1.764 -1.734 3.003 v 41.532 c 0 1.239 0.661 2.384 1.734 3.003 l 35.968 20.766 c 1.073 0.62 2.395 0.62 3.468 0 l 35.968 -20.766 c 1.073 -0.62 1.734 -1.764 1.734 -3.003 V 24.234 C 84.436 22.995 83.775 21.85 82.702 21.231 z M 48.943 58.328 c 3.17 0 6.243 -1.135 8.653 -3.197 l 5.961 6.97 c -4.07 3.482 -9.26 5.399 -14.614 5.399 c -12.407 0 -22.5 -10.093 -22.5 -22.5 c 0 -12.407 10.093 -22.5 22.5 -22.5 c 5.354 0 10.544 1.918 14.614 5.399 l -5.961 6.97 c -2.411 -2.061 -5.485 -3.197 -8.653 -3.197 c -7.35 0 -13.329 5.979 -13.329 13.329 C 35.614 52.349 41.594 58.328 48.943 58.328 z" />
</g>
</svg>,
    'C',
);

export default CIcon




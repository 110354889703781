import {createSvgIcon} from "@mui/material";
import React from "react";

const MaterialUiIcon = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height="1em"
        width="1em"
    >
        <path d="M8 16.61v-1.24l6-3.46V7.23l-5 2.89-5-2.89V13l-1 .58L2 13V5l1.07-.62L9 7.81l3.93-2.27 2-1.16L16 5v8.06L10.92 16l4.05 2.33 5.03-2.9V11l1-.58 1 .58v5.58l-7.03 4.06L8 16.61m14-6.86l-1 .58-1-.58V8.58L21 8l1 .58v1.17z" />
    </svg>,
"Material-ui"
)

export default MaterialUiIcon;

